import { codeSplit, Controller } from 'bernie-core';
import { PageData } from 'bernie-http';
import { BASEPATH } from 'common/basepath';

// "controllers" map directly to react-router routes, can additionally provide data
export class ConversationAuthenticationController implements Controller {
  public pageId = 'page.Customer-Service,U,10';
  public path = `${BASEPATH}/smsauth`;
  public routeName = 'conversationAuthentication';
  public bundles = [];

  public component = codeSplit(() => import(/* webpackChunkName: "conversation auth" */ '../views/conversation-auth'));
  public exact = true;

  public fetch(): Promise<PageData> {
    return Promise.resolve({ title: 'Conversation Authentication Page', pageId: this.pageId });
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({
      title: 'Conversation Authentication Page',
      pageId: this.pageId,
      appName: 'Conversation Authentication Page',
    }) as Promise<PageData>;
  }
}
