import { codeSplit, Controller, FetchOptions } from 'bernie-core';
import { PageData } from 'bernie-http';
import { helpCenterPageIdentifier } from '../common/constant';
import PathToRegex from 'path-to-regex';
import { BASEPATH, VRBO_BASEPATH } from '../common/basepath';
// "controllers" map directly to react-router routes, can additionally provide data
export class VaOnlyController implements Controller {
  public pageId = helpCenterPageIdentifier;
  public path = `${BASEPATH}/helpcenter/vaonly`;
  public routeName = 'vaonly';
  public bundles = [];

  public component = codeSplit(() => import(/* webpackChunkName: "vaonly" */ '../views/va-only'));
  public exact = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetch(options: FetchOptions): Promise<PageData> {
    const validator = new PathToRegex(`${VRBO_BASEPATH}/helpcenter/vaonly`);

    if (!validator.match(options?.request?.url)) {
      options?.response?.setStatus(404);
    }
    return Promise.resolve({ title: 'VAC', pageId: this.pageId });
  }

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({ title: 'VAC', pageId: this.pageId, appName: 'Help Center VAC' }) as Promise<PageData>;
  }
}
