import { HelpCenterController } from './helpcenter-controller';
import { KnowledgeBaseController } from 'controllers/knowledge-base-controller';
import { VaOnlyController } from 'controllers/va-only-controller';
import { ConversationAuthenticationController } from 'controllers/conversation-auth-controller';

export const controllers = [
  HelpCenterController,
  KnowledgeBaseController,
  VaOnlyController,
  ConversationAuthenticationController,
];
