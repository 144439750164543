export const egClickStreamConstants = {
  egClickStreamEventVersion: '1.0.0',
  egClickStreamEventNames: {
    articleLinkSelected: 'cta.selected',
    intentButtonSelected: 'intent_button.selected',
    helpCenterPageView: 'help_center.presented',
    virtualAgentInitiated: 'virtual_agent.initiated',
    virtualAgentFailed: 'virtual_agent.failed',
    helpCenterContactUsSelected: 'help_center_contact_us.selected',
    taapAcademyComponentSelected: 'help_center_taap_academy.selected',
    helpCenterTripSelected: 'help_center_trip.selected',
    helpCenterTripBookingSelected: 'help_center_trip_booking.selected',
    helpCenterPersonalizationIntentSelected: 'help_center_trip_booking_intent.selected',
    helpCenterSignInSelected: 'help_center_sign_in.selected',
    helpCenterSignInClosed: 'help_center_sign_in.closed',
  },
  egClickStreamEventTypes: {
    pageView: 'Page View',
    impression: 'Impression',
    outcome: 'Outcome',
    interaction: 'Interaction',
  },
  egClickStreamEventCategories: {
    virtualAgent: 'virtual_agent',
    helpCenter: 'help_center',
    helpCenterTrip: 'help_center_trip',
  },
  egClickStreamEventActionLocation: {
    intentButtons: 'home_page',
  },
  egClickStreamEventPageName: 'Help Articles',
  egClickStreamEventPageNameDetailed: 'Help Center',
  egClickStreamEventAppName: 'ecp-help-center',
  egClickStreamLocation: 'home_page',
  egClickStreamEventWorkFlowItems: {
    work_flow_list: [
      {
        intent: 'QUESTION_AND_ANSWER',
        automation_status: 'STARTED',
        product_line: 'UNKNOWN',
        order_line_id: '',
        channel: 'WEB',
      },
    ],
  },
};

export const helpCenterPageIdentifiers = {
  pageName: 'page.Customer-Service',
  lineOfBusiness: 'U',
  funnelLocation: '10',
};

export const helpCenterPageIdentifier = `${helpCenterPageIdentifiers.pageName},${helpCenterPageIdentifiers.lineOfBusiness},${helpCenterPageIdentifiers.funnelLocation}`;

export const fallbackError = 'codeFailure';

export const vacEvents = {
  vacConversationCreatedEvent: 'VAC_ConversationCreated',
  vacConversationJoinedEvent: 'VAC_ConversationJoined',
  vacInitializedEvent: 'VAC_Initialized',
  vacLazyInitializedEvent: 'VAC_LazyInitializeFailed',
  vacCreateFrameFailedEvent: 'VAC_CreateFrameFailed',
  vacStartFrameFailedEvent: 'VAC_StartFrameFailed',
  vacConversationCreateFailedEvent: 'VAC_ConversationCreateFailed',
  vacConversationJoinFailedEvent: 'VAC_ConversationJoinFailed',
  vacFabPresented: 'VAC_FabPresented',
};

export const VacConfig = {
  VACTimeout: 30000,
  successEventsForEmbedded: [vacEvents.vacConversationCreatedEvent, vacEvents.vacConversationJoinedEvent],
  successEventsForFloating: [vacEvents.vacInitializedEvent, vacEvents.vacConversationCreatedEvent],
  errorEvents: [
    vacEvents.vacLazyInitializedEvent,
    vacEvents.vacCreateFrameFailedEvent,
    vacEvents.vacStartFrameFailedEvent,
    vacEvents.vacConversationJoinFailedEvent,
  ],
  vacHandledErrorEvents: [vacEvents.vacConversationCreateFailedEvent, vacEvents.vacConversationJoinFailedEvent],
  errorEventMap: {
    [vacEvents.vacLazyInitializedEvent]: fallbackError,
    [vacEvents.vacCreateFrameFailedEvent]: fallbackError,
    [vacEvents.vacStartFrameFailedEvent]: fallbackError,
  },
  errorConfig: {
    [fallbackError]: {
      iconName: 'sentiment_2',
      errorHeader: "Oops, looks like we've lost the connection",
      errorSubheader: 'In the meantime you can check out some articles from the links below.',
      refreshButton: {
        enabled: true,
        buttonText: 'Refresh',
      },
    },
  },
};
