import { action, observable } from 'mobx';
import { Store } from 'bernie-plugin-mobx';
import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';

interface VirtualAgentControlStoreDefaults {
  conversationId: string;
  participantId: string;
}

export class VirtualAgentControlStore extends Store {
  /* tslint:disable:unnecessary-constructor */
  constructor(state: SerializedData = {}, logger: Logger = NOOP_LOGGER) {
    super(state, logger);
  }

  public hydrate(): void {
    // Since I'm not hydrating anything server side, I don't need this
  }

  public static DEFAULTS: VirtualAgentControlStoreDefaults = {
    conversationId: '',
    participantId: '',
  };

  @observable
  public conversationId: string;
  @observable
  public participantId: string;

  @action
  public setVirtualAgentControlIdentifiers(conversationId: string, participantId: string): void {
    this.conversationId = conversationId;
    this.participantId = participantId;
  }
}
